import { Module } from 'vuex';
import { IRootState } from '..';
import setData from '../shared/setData';
import { signAPIv2 } from '@/api';
import { ISignCreateRequest, ISignCreateResponse } from '@/api/_v2/sign';

export interface ISignState {
	applicantSign: null;
	coApplicantSign: null;
	payment: null;
}

const sign: Module<ISignState, IRootState> = {
	namespaced: true,
	state: {
		applicantSign: null,
		coApplicantSign: null,
		payment: null
	},
	mutations: {
		setData,
		resetState(state) {
			state.applicantSign = null;
			state.coApplicantSign = null;
			state.payment = null;
		}
	},
	actions: {
		async getSignApplicationDataAsync({ commit, state }, request: any): Promise<any | null> {
			if (request) {
				const response = await signAPIv2.getSignApplicationDataAsync({ applicationToken: request });
				return response.payment;
			}
		},
		async createSignAsync({ commit }, request: ISignCreateRequest): Promise<ISignCreateResponse> {
			const response = await signAPIv2.createSignAsync(request);
			return response;
		},
		async getDynamicApyConfigurationsAsync({ commit, state }, request: any): Promise<any | null> {
			const response = await signAPIv2.getDynamicApyConfigurationsAsync();
			return response;
		}
	}
};

export default sign;
