import axios from '@/utils/axios';

export interface ISignDataGetRequest {
	applicationToken: string;
}
export interface ISignDataGetResponse {
	payment: {};
}
export interface ISignCreateRequest {
	applicationToken: string;
	signatures: ISignature[]
}
export interface ISignature
{
	applicantSignature: string,
	applicantToken: string
}

export interface ISignCreateResponse {
	submitted: boolean,
	worthinessResult: number
}
export interface IDynamicApyConfiguration {
	id: number;
	productId: number;
	value: string;
}
export interface IDynamicApyConfigurationsResponse {
	dynamicApyConfigurations: IDynamicApyConfiguration[];
}
export interface ISignUpdateRequest {}
export interface ISignUpdateResponse {}
const getSignApplicationDataAsync = async (request: ISignDataGetRequest): Promise<ISignDataGetResponse> => {
	try {
		const { data } = await axios.post<ISignDataGetResponse>('/api/v2/Sign/ApplicationData', request);

		return data;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};
const createSignAsync = async (request: ISignCreateRequest): Promise<ISignCreateResponse> => {
	try {
		const { data } = await axios.post<ISignCreateResponse>('/api/v2/Sign/Application/Sign', request);

		return data;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};
const updateSignAsync = async (request: ISignUpdateRequest): Promise<ISignUpdateResponse> => {
	try {
		const { data } = await axios.put<ISignUpdateResponse>('/api/v2/Sign', request);

		return data;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};
const getDynamicApyConfigurationsAsync = async (): Promise<IDynamicApyConfigurationsResponse> => {
	try {
		const { data } = await axios.get<IDynamicApyConfigurationsResponse>(
			`/api/v2/Configuration/DynamicAPY`
		);

		return data;
	} catch (error) {

		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};

export { createSignAsync, updateSignAsync, getSignApplicationDataAsync, getDynamicApyConfigurationsAsync };
