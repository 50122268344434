import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import axios from '@/utils/axios';
import * as modules from './modules';
import { IModuleState } from './modules';
import setData from './shared/setData';

//v1 remnant
import { GetApplicationRehydrationData } from '@/api/resume';
import localStorage from './shared/localStorage';
import createPreviousRoutes from '@/utils/createPreviousRoutes';

export interface IRootState extends IModuleState {
	/*isLoading: boolean;*/
	//defaultsLoaded: boolean;
	//unsupportedBrowser: boolean;
	//v1 Remnants
	//applicationConfigurationLoaded: boolean;
	//siteAvailability: null;
	//token: string | null;
	//showEmailField: boolean;
	//showCaptcha: boolean;
	//siteKey: string;
}

Vue.use(Vuex);

const store: StoreOptions<IRootState> = {
	strict: process.env.NODE_ENV !== 'production',
	plugins: [
		createPersistedState({
			storage: window.sessionStorage,
			paths: [
				'applicant',
				'application',
				'affinity',
				'audit',
				'coApplicant',
				'cardSelection',
				'config',
				'employment',
				'identification',
				'routeEnforcer',
				'sessionTimer',
				'modal',
				'document',
				//v1 Remnants
				'disclosures',
				'funding.total',
				'identityVerification',
				'jointOwner',
				'print',
				'products',
				'routeEnforcer',
				'sessionTimer',
				'states',
				'user',
				'eligibility',
				'memberAdvocate',
				'overdraft'
			]
		})
	],
	modules,
	state: {
		//isLoading: false,
		//defaultsLoaded: false,
		//unsupportedBrowser: false,
		//v1 remnants
		//applicationConfigurationLoaded: false,
		//siteAvailability: null,
		//token: null,
		//showEmailField: false,
		//showCaptcha: false,
		//siteKey: ''
	},
	mutations: {
		//setLoading(state, isLoading) {
		//	state.isLoading = isLoading;
		//},
		setData,
		resetState(state) {
			//state.isLoading = false;
			//state.defaultsLoaded = false;
			//state.unsupportedBrowser = false;
		}
	},
	actions: {
		//************BEGIN REMNANT********************************/
		async reHydrateApplication({ dispatch, commit }) {
			const appToken = localStorage.get('encodedApplicationId');
			const result = await GetApplicationRehydrationData(appToken);

			const user = {
				applicationId: result.applicationId,
				encodedApplicationId: appToken,
				firstName: result.firstName,
				lastName: result.lastName,
				memberType: result.memberType,
				applicationType: result.hasJoint ? 'joint' : 'single'
			};

			await commit('user/resetState', {
				...user
			});

			await dispatch('products/resumeProducts', {
				availableProducts: result.availableProducts,
				selectedProducts: result.selectedProducts
			});
			await commit('routeEnforcer/replaceHistory', createPreviousRoutes(result.currentPage));
		},
		//***************END REMNANT*/
		resetState({ commit, dispatch }) {
			dispatch('applicant/resetState');
			dispatch('coApplicant/resetState');
			commit('application/resetState');
			commit('employment/resetState');
			commit('affinity/resetState');
			commit('cardSelection/resetState');
			commit('overdraft/resetState');
			commit('audit/resetState');
			commit('funding/resetState');
			commit('jointOwner/resetState', {});
			commit('products/resetState');
			commit('identityVerification/resetState');
			commit('user/resetState', {});
			commit('print/resetState');
			commit('disclosures/resetState');
			commit('config/resetState');
			commit('modal/resetState');
			commit('document/resetState');
		}
	},
	getters: {}
};

export default new Vuex.Store(store as any);
