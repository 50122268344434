import { axios } from '@/utils/axios';

export interface IUploadDocumentRequest {
	applicationToken: string;
	applicantToken: string;
	applicantType: number;
	documentUploadType: number;
	files: FormData;
}

export interface IUploadDocumentResponse {
	posted: boolean;
	hasDuplicate: boolean;
}

export interface IDocumentUploadConfiguration {
	documentUploadType: Number;
	fileExtensionsAllowed: string[];
	maxFileSize: Number;
	maxUploadSizePerRequest: Number;
	maxUploadRequests: Number;
	enabled: boolean;
	optional: boolean;
	displayTitle: string;
	hintText: string;
}

export interface IDocumentUploadConfigurationRequest {
	identificationTypeId: Number;
	applicationToken: string;
}

export interface IDocumentApplicationCreateRequest {
	applicationToken: string;
}

export interface IDocumentUploadConfigurationResponse {
	documentUploadConfigurations: IDocumentUploadConfiguration[];
}

const getDocumentUploadConfiguration = async (
	request: IDocumentUploadConfigurationRequest
): Promise<IDocumentUploadConfigurationResponse> => {
	try {
		const { data } = await axios.post<IDocumentUploadConfigurationResponse>(
			`api/v2/Document/Upload/Configurations`,
			request
		);
		return data;
	} catch (error) {
		throw error;
	}
};

const uploadDocuments = async (request: IUploadDocumentRequest): Promise<IUploadDocumentResponse> => {
	let endpoint = `api/v2/Document/Upload/DocumentUploadType/${request.documentUploadType}/ApplicationToken/${request.applicationToken}/ApplicantToken/${request.applicantToken}/`;
	console.log('setting content type', axios.defaults.headers);
	const { data } = await axios.post(endpoint, request.files, {
		headers: {
			'Content-Type': 'multipart/form-data'
		},
		data: request.files
	});
	return data;
};

const createDocumentApplication = async (request: IDocumentApplicationCreateRequest): Promise<any> => {
	try {
		const { data } = await axios.post<any>(
			`/api/v2/Document/CreateApplicationDocument/${request.applicationToken}`
		);
		return data;
	} catch (error) {
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};

export default {
	getDocumentUploadConfiguration,
	uploadDocuments,
	createDocumentApplication
};

export { getDocumentUploadConfiguration, uploadDocuments, createDocumentApplication };
